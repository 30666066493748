<template>
  <div class="logo-svg">
    <svg height="40" viewBox="0 0 151 254" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Footer Logo</title>
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="JACKDAW-LOGO">
          <polygon id="Footer-Logo" points="150.4 0 75.2 50.719 75.2 152.17 -9.09494702e-13 101.45 -9.09494702e-13 202.89 75.2 253.62 150.4 202.89"></polygon>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'GeometricLogo'
}
</script>

