import { APP_METADATA } from '@/constants';

/**
 * Compose the title string for the window
 * 
 * seoDivider [string]
 * appendTitleValue ['sitename', string]
 * appendTitleValueOnHomepage ['', 1]
 * title [string]
 * 
 * @return {string}
 */
export function titleHelper(dynamicTitle)  {
  // If the CMS is set not to append the site title return just the dynamic title
  if (!APP_METADATA.appendTitleValue) return dynamicTitle

  // If on the homepage and the CMS is set to NOT append the title on the homepage
  if (this.$route.name === "home" && !APP_METADATA.appendTitleValueOnHomepage) return dynamicTitle

  // Compose title value
  const titleValue = APP_METADATA.appendTitleValue === "sitename" ? APP_METADATA.title : APP_METADATA.appendTitleValue

  // Return composition
  return `${dynamicTitle} ${APP_METADATA.seoDivider} ${titleValue}`
} 