<template>
  <div
    ref="container"
    class="image"
    @mouseover="mouseIsOver= true"
    @mouseleave="mouseIsOver = false"
  >
      <img
        v-if="!ie11"
        ref="image"
        :src="_src"
        :alt="alt"
        class="image-lazy"
        :class="[{'image-loading': !imgLoaded}, className]"
        :style="style"
      />
      <div
        v-if="ie11"
        ref="image"
        :alt="alt"
        class="image-lazy"
        :class="[{'image-loading': !imgLoaded}, className]"
        :style="style"
      ></div>
      <div
        v-if="pinit"
        @click="pinMe"
        class="pin-it"
      ></div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import "intersection-observer"
import { mapState } from 'vuex'
import { pinit } from '@/utils'


export default {
  name: 'CoreImage',

  props: {
    src: {
      type: Array,
      default: () => ([])
    },
    alt: String,
    focalPoint: {
      type: Object,
      default: () => ({
        x: 0.5,
        y: 0.5
      })
    },
    pinOne: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "image-contained"
    },
    pinit: {
      type: Boolean,
      default: false
    },
    dprEnabled: {
      type: Boolean,
      default: false
    },
    // As we are adding an inline CSS transform to handle focal points,
    // we must compound extra transform rules inline, use cssTransform to do
    // that, or enable pre defined functions, additionally, if there are 
    // transform rules that need applying on hover, use hover transform.
    // As these are likley to remain consistant sitewide just update the
    // defaults, however the prop is provided for flexibility
    transform: {
      type: String,
      default: ""
    },
    hoverTransform: {
      type: String,
      default: ""
    },
    lazyTransformCSS: {
      type: String,
      default: ""
    }
  },

  data: () => ({
    imgLoaded: false,
    w: 0,
    h: 0,
    observer: null,
    xDiff: 0,
    yDiff: 0,
    mouseIsOver: false,
    applyTransforms: false
  }),

  computed: {
    ...mapState({
      ie11: state => state.ie11
    }),
    style() {
      // Craft focus point needs to be mapped to css ie y = x => y = -2x + 1
      // if (Object.keys(this.focalPoint).length)
      const normalX = -2 * this.focalPoint.x + 1
      const normalY = -2 * this.focalPoint.y + 1

      // Divide by 2 as it is centered
      const pxShiftX = normalX * (this.xDiff / 2)
      const pxShiftY = normalY * (this.yDiff / 2)

      // To be safe against transforms affecting the calculation of xDiff and yDiff
      // apply transform props after constants have been calculated. Then apply 
      // transforms in order to ensure an order of inheretence:
      // transform, lazyTransformCSS, hoverTransform, translate(-50%, -50%), translateX, translateY
      return {
        transform: `${this.applyTransforms ? this.transform : ""} ${(!this.imgLoaded && this.applyTransforms) ? this.lazyTransformCSS : ""} ${(this.mouseIsOver && this.applyTransforms) ? this.hoverTransform : ""} translate(-50%, -50%) translateX(${pxShiftX}px) translateY(${pxShiftY}px)`,
        ...(this.ie11 && {
          backgroundImage: `url('${this._src}')`,
          backgroundSize: 'cover'
        })
      };
    },
    lazySrc() {
      return [...this.src].pop().src
    },
    targetSrc() {
      const target = [...this.src].reverse().find(src => src.width > this.w && src.height > this.h)
      return target ? target.src : this.src[0].src
    },
    _src() {
      return this.imgLoaded ? this.targetSrc : this.lazySrc
    }
  },

  mounted() {
    this.debouncedResize = debounce(this.getContainerSizes, 300)
    window.addEventListener('resize', this.debouncedResize, false)

    // Next tick to be safe
    this.$nextTick(() => {
      this.getContainerSizes() // Calculate W and H for intersection observer

      this.observer = new IntersectionObserver(this.imageInView, {
        rootMargin: "1000px 0px 1000px 0px"
      })
      this.observer.observe(this.$refs.image)
    })
  },

  updated() {
    this.getContainerSizes() // Calculate correct inner img width and height
    this.applyTransforms = true
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedResize, false)
    this.observer.disconnect()
  },

  methods: {
    imageInView(entries, observer) {
      entries.forEach(entry => { // Should always just be 1 element in the array anyway
        if (entry.isIntersecting) {
          const img = new Image()
          img.src = this.targetSrc
          img.onload = () => {
            this.imgLoaded = true
            observer.disconnect()
          }
        }
      })
    },
    getContainerSizes() {
      const container = this.$refs.container.getBoundingClientRect()
      const image = this.$refs.image.getBoundingClientRect()

      this.xDiff = image.width - container.width
      this.yDiff = image.height - container.height

      const dpr = this.dprEnabled ? window.devicePixelRatio : 1
      this.w = container.width * dpr
      this.h = container.height * dpr
    },
    pinMe() {
      pinit().then(PinUtils => {
        PinUtils.pinOne({
          url: window.location.href, 
          media: this.pinOne, 
          description: this.alt
        })
      });
    }
  }
}
</script>