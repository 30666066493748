<template>
  <div v-if="!consentGiven" class="cookies">
    <CoreContainer :contained="true">
      <CoreRow>
        <CoreGrid>
          <p>This site uses cookies. By continuing to browse the site, you are agreeing to our use of cookies.</p>
        </CoreGrid>
        <CoreGrid class="cookies-right">
          <div class="cookies-right-button" @click="acceptCookies"><p>Ok, got it</p></div>
        </CoreGrid>
      </CoreRow>
    </CoreContainer>
  </div>
</template>

<script>
export default {
  name: 'BlockCookies',

  data: () => ({
    consentGiven: false
  }),

  created() {
    this.checkConsent()
  },

  methods: {
    acceptCookies() {
      const consent = 'cookieConsent=true'
      
      const date = new Date()
      date.setTime(date.getTime() + 90*24*60*60*1000) // 90 day expiarey
      const expires = "expires="+ date.toUTCString()

      document.cookie = [consent, expires].join(';')
      this.checkConsent()
    },
    checkConsent() {
      this.consentGiven = !!decodeURIComponent(document.cookie).split(';').filter(item => item.trim().startsWith('cookieConsent=')).length
    }
  }
}
</script>