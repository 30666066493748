import get from 'lodash.get'

/**
 * Constants
 */

export const COLORS = Object.freeze({
  default: 'bg-light-beige',
  white: 'bg-white',
  black: 'bg-black',
  lightBeige: 'bg-light-beige',
  beige: 'bg-beige',
  putty1: 'bg-putty-1',
  putty2: 'bg-putty-2',
  gray1: 'bg-gray-1',
  gray2: 'bg-gray-2'
})

export const AUXCOLORS = Object.freeze({
  default: 'auxiliary-bg-light-beige',
  white: 'auxiliary-bg-white',
  black: 'auxiliary-bg-black',
  lightBeige: 'auxiliary-bg-light-beige',
  beige: 'auxiliary-bg-beige',
  Putty1: 'auxiliary-bg-putty-1',
  putty2: 'auxiliary-bg-putty-2',
  gray1: 'auxiliary-bg-gray-1',
  gray2: 'auxiliary-bg-gray-2'
})

export const IMAGE_ORIENTATIONS = Object.freeze({
  portrait: 'portrait',
  landscape: 'landscape',
  letterbox: 'letterbox',
  fluid: 'fluid',
  original: 'original'
})

export const BREAKPOINT_MODIFIERS = Object.freeze({
  default: '',
  ns: '-ns',
  m: '-m',
  l: '-l'
})

export const V_ROW_POSITIONS = Object.freeze({
  top: '',
  center: 'self-center',
  bottom: 'self-end'
})

export const H_ROW_POSITIONS = Object.freeze({
  left: '',
  center: 'center',
  right: 'ml-auto'
})

export const FLUID_IMAGE_SIZES = Object.freeze({
  50: 'fluid-50',
  100: ''
})

export const FLUID_BLEEDS = Object.freeze({
  full: 'fluid-full-bleed',
  framed: ''
})

export const GRID_CLASS = Object.freeze({
  1: 'grid-1',
  2: 'grid-2',
  3: 'grid-3',
  4: 'grid-4',
  5: 'grid-5',
  6: 'grid-6'
})

export const RELATED_BLOCK_ROUTE_NAMES = Object.freeze({
  projectsEntry: 'projectsEntry',
  latestEntry: 'latestEntry',
  home: 'latestEntry'
})

export const RELATED_BLOCK_CHANNEL = Object.freeze({
  projectsEntry: 'projects',
  latestEntry: 'latest',
  home: 'latest'
})

export const NAVIGATION_ROUTES = Object.freeze({
  navProjects: 'projects',
  navStudio: 'studio',
  navLatest: 'latest',
  navContact: 'contact'
})

export const TYPE_CLASSS = Object.freeze({
  1: 'sans',
  2: 'sans-extra-bold',
  3: 'serif',
  4: 'serif-italic',
  5: 'serif-bold'
})

export const TRANSITION_GROUPS = Object.freeze({
  home: ['home'],
  navProjects: ['projects', 'projectsEntry'],
  navStudio: ['studio'],
  navLatest: ['latest', 'latestEntry'],
  navContact: ['contact']
})

export const SOCIAL_MEDIA_URL_PREFIXS = Object.freeze({
  twitter: 'https://twitter.com/',
  instagram: 'https://www.instagram.com/',
  behance: 'https://www.behance.net/',
  pinterest: 'https://www.pinterest.co.uk/'
})

export const NAVBAR_HEIGHT = `3rem`

// Application config getter
export const getConfig = path => get(window.APP_CONFIG || {}, path)

// Application config constants
export const APP_API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT
export const APP_GLOBAL_DATA_HEADINGS = getConfig('globals.dataHeadings')
export const APP_GLOBAL_RELATED_HEADINGS = getConfig('globals.relatedHeadings')
export const APP_GLOBAL_NAVIGATION = getConfig('globals.navigation')
export const APP_GLOBAL_HERO_TEXT = getConfig('globals.heroText')
export const APP_GLOBAL_SOCIAL_MEDIA = getConfig('globals.socialMedia')
export const APP_GLOBAL_CONTACT = getConfig('globals.contact')
export const APP_PREVIEW_MODE = getConfig('previewMode')
export const APP_WEBP_SUPPORT = getConfig('webp')
export const APP_METADATA = getConfig('globals.metadata')
export const VUE_APP_LINK_MAKE = process.env.VUE_APP_LINK_MAKE
export const VUE_APP_LINK_PLUS = process.env.VUE_APP_LINK_PLUS
