import Vue from 'vue'

import CoreContainer from './Container.Core'
import CoreRow from './Row.Core'
import CoreGrid from './Grid.Core'
import CoreCarousel from './Carousel.Core'
import CoreImage from './Image.Core'
import CoreVideo from './Video.Core'
import CoreTile from './Tile.Core'
import CoreTransition from './Transition.Core'
import CoreAnimation from './Animation.Core'

const core = [
  CoreContainer,
  CoreRow,
  CoreGrid,
  CoreCarousel,
  CoreImage,
  CoreVideo,
  CoreTile,
  CoreTransition,
  CoreAnimation
]

core.forEach(component => Vue.component(component.name, component))