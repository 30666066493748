export const storage = {
  get enabled() {
    try {
      this.api.setItem('_','_')
      this.api.removeItem('_')
      return true
    }
    catch(e) {
      return false
    }
  },

  get api() {
    return window.localStorage
  }
}