<template>
  <div :class="[contained ? 'contained' : 'container']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CoreContainer',

  props: {
    contained: Boolean
  },
}
</script>
