import { $http } from '@/utils'

/**
 * Gets channel entries
 * @param  {string} channel   The channel i.e. projects
 * @param  {number} limit     Optionally limit entries
 * @param  {array}  exclude   Optionally exclude entries by entry id
 * @return {object}
 */
export const getChannel = ({ channel, limit = null, exclude = [] }) => {
  return $http.get(`/channel/${channel}`, {
    params: {
      limit   : limit,
      exclude : exclude
    }
  })
}

/**
 * Gets project index
 * @return {object}
 */
export const getProject = () => {
  return $http.get('/projects')
}

/**
 * Gets a project entry
 * @param  {string} slug Entry slug
 * @return {object}
 */
export const getProjectEntry = ({ slug }) => {
  return $http.get(`/projects/${slug}`)
}

/**
 * Gets all blog entries
 * @return {object}
 */
export const getHomepage = () => {
  return $http.get('/home')
}

/**
 * Gets the studio page
 * @return {object}
 */
export const getStudio = () => {
  return $http.get('/studio')
}

/**
 * Gets the contact page
 * @return {object}
 */
export const getContact = () => {
  return $http.get('/contact')
}

/**
 * Gets latest index
 * @return {object}
 */
export const getLatest = () => {
  return $http.get('/latest')
}

/**
 * Gets a blog entry
 * @param  {string} slug Entry slug
 * @return {object}
 */
export const getLatestEntry = ({slug}) => {
  return $http.get(`/latest/${slug}`)
}