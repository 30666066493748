import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Pre-load fonts to make them available for render
import { fontLoader } from '@/utils'
fontLoader()

// Import Directives
import './directives'

// Import Filters
import './filters'

// Register vue-meta handler
// as we are not using server side rendering here is fine
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  keyName: 'metadata'
})

// Register global constants
import * as constants from '@/constants'
Vue.prototype.$const = Object.freeze(constants)

// Register API handlers
import * as api from './api'
Vue.prototype.$api = api

// Register core components
import '@c/core'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
