import { GRID_CLASS } from '@/constants'
import "intersection-observer"

let instances = {}
let counter = 0

const createObserver = ({
  target,
  callback = observerCallback,
  options = {
    root: null, 
    rootMargin: '0% 0%', // V | H
    threshold: 0
  }
}) => {
  const observer = new IntersectionObserver(callback, options)

  Array.isArray(target) ? target.forEach(target => target && observer.observe(target)) : target && observer.observe(target)

  return observer
}

const destroyObserver = observer => !!observer && observer.disconnect()

const observerCallback = entries => {
  entries.forEach(entry => {
    // Add a class if below the screen
    // Remove a class if on or above screen
    !entry.isIntersecting && entry.boundingClientRect.y > entry.rootBounds.bottom && entry.target.classList.add('not-intersecting')
    entry.isIntersecting && entry.target.classList.remove('not-intersecting')
  })
}

const selectElement = (el, binding) => {
  // if element id a grid element, select its child, unless v-intersect="'grid'" is specified
  const containsGridClass = Object.keys(GRID_CLASS).some(className => Array.from(el.classList).includes(className)) 
  return (!(binding.expression === `'grid'`) || containsGridClass) ? el.firstElementChild : el

  // Alt Logic
  // if v-intersect="'children'" select children
  // return binding.expression === `'children'` ? el.firstElementChild : el
}

const directive = {
  inserted: (el, binding) => {
    const _el = selectElement(el, binding)

    if (_el) {
      // Generate UID for local instance and dataset
      const id = counter++
      _el.setAttribute('intersect', id)
      // Create observation
      instances[id] = createObserver({target: _el})
    }
  },

  unbind: (el, binding) => {
    const _el = selectElement(el, binding)
    _el && destroyObserver(instances[_el.getAttribute('intersect')])
  }
}

export default directive