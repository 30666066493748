<template>
  <div class="data normal-content-spacing-b">
    <hr>
    <CoreContainer :contained="true" class="mt4">
    
      <CoreRow>
        <CoreGrid>
          <h4 class="f5 mb4">{{ dataHeading }}</h4>
        </CoreGrid>
        <CoreGrid>
          <ul class="list">
            <template v-for="(line, i) in filteredData">
              <BlockDataLine :handle="line[0]" :content="line[1]" :key="i" />
            </template>

            <!-- Share Block -->
            <BlockShare />
          </ul>
        </CoreGrid>
      </CoreRow>
    </CoreContainer>
  </div>
</template>

<script>
  import BlockShare from '@c/block/Share.Block'
  import BlockDataLine from '@c/block/data/DataLine.Block'

  export default {
    name: 'BlockData',

    components: {
      BlockDataLine,
      BlockShare
    },

    props: {
      data: Array,
    },

    computed: {
      dataHeading() {
        return this.$const.APP_GLOBAL_DATA_HEADINGS[this.$route.name]
      },
      filteredData() {
        return this.combineSimilar(this.data)
      }
    },

    methods: {
      combineSimilar(Arr) {
        return Arr.reduce((r, {handle, content}) => {
          return r.set(handle, (r.get(handle) || []).concat(content))
        }, new Map()) // .values()] to remove keys from result
      }
    }
  }
</script>