<template>
  <div
    class="filter background-transition"
    :class="[{'contained' : isMobile, 'filter-open' : open}, backgroundColor]"
    :style="scrollHeight"
    @mouseenter="handleMouse"
    @mouseleave="handleMouse"
    @click="handleClick"
  >

    <span class="filter-head">
      <span class="filter-title">Filter</span><span class="filter-title mh2"> — </span>
      <span
        class="filter-catagory filter-selected"
        :class="{ 'filter-catagory-not-active' : !active(selected.handle) }"
      >{{ (open && !isMobile) ? '' :selected.text }}</span>
    </span>

    <span class="filter-catagories" ref="filterCatagories">
      <span
        v-for="(tag, i) in _tags"
        :key="i"
        class="filter-catagory filter-separator"
        :class="{ 'filter-catagory-not-active' : !active(tag.handle)}"
        @click="$emit('filter', tag.handle)"
      >{{ tag.text }}</span>
    </span>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'BlockFilterBar',

    props: {
      tags: {
        type: Set,
        default: () => (new Set([]))
      },
      activeFilter: {
        type: [Boolean, String],
        default: false
      }
    },

    data: () => ({
      all: {
        text: 'All',
        handle: false
      },
      open: false,
      lastEvent: null
    }),

    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
        backgroundColor: state => state.backgroundColor,
      }),
      selected() {
        return this.activeFilter ? {text: this.activeFilter, handle: this.activeFilter} : this.all
      },
      _tags() {
        const tags = new Set([this.all, ...[...this.tags].map(tag => ({text: tag, handle: tag}))])
        return tags
      },
      scrollHeight() {
        const height = this.open ? `calc(${this.$refs.filterCatagories.getBoundingClientRect().height}px + ${this.$const.NAVBAR_HEIGHT})` : null
        return { maxHeight: height }
      }
    },

    methods: {
      active(tag) {
        return [tag].some(el => [this.activeFilter].includes(el))
      },
      handleClick(e) {
        !(this.lastEvent && this.lastEvent === 'mouseenter') && (this.open = !this.open)
        this.lastEvent = e.type
      },
      handleMouse(e) {
        this.open = e.type === 'mouseenter'
        this.lastEvent = e.type
      }
    },
  }
</script>
