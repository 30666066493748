<template>
  <div class="hero-block">
    <!-- Hero -->
    <CoreContainer>
      <CoreVideo
        v-if="video !== null"
        v-bind="video.video"
        :allowAudio="video.allowAudio"
        class="hero-image"
      />
      <CoreCarousel
        v-if="video === null && images.length > 1"
        :imgs="images"
        class="edge-to-edge"
        :followMouse="followMouse"
        :flickity="flickity"
      />
      <CoreImage 
        v-if="video === null && images.length === 1"
        class="hero-image"
        v-bind="images"
        :dprEnabled="true"
      />
    </CoreContainer>
  </div>
</template>

<script>
  export default {
    name: 'BlockHero',

    props: {
      images : Array,
      video : Object,
      flickity : {
        type: Object,
        default: () => ({})
      },
      followMouse: {
        type: Boolean,
        default: true
      }
    },


  };
</script>