<template>
  <div class="mute-unmute">
    <div :style="mute" class="video-control-icon-container">
      <img src="~@/assets/icons/volume_mute.svg" alt="mute" class="video-control-icon">
    </div>
    <div :style="unmute" class="video-control-icon-container">
      <img src="~@/assets/icons/volume_up.svg" alt="unmute" class="video-control-icon">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoreMediaMuteUnmute',

  props: { 
    muted: Boolean
  },

  computed: {
    mute() {
      return {
        display: !this.muted ? 'none' : 'flex'
      }
    },
    unmute() {
      return {
        display:  this.muted ? 'none' : 'flex'
      }
    }
  }
}
</script>
