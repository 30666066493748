<template>
  <div @click="routeMe(slug)" class="tile pointer">
    <CoreImage :class="imageClass" v-bind="image" hoverTransform="scale(1.02)" className="image-contained-tile" />
    <div class="tile-textbox items-baseline">
      <h4 class="invert tile-title">{{ title }}<span class="tile-hyphen"> - </span></h4>
      <p class="invert tile-tagline">{{ tagline }} </p>
    </div>
    <div class="mt1 mb4 flex">
      <h6 class="tag-list f7" v-for="(tagItem, tagKey) in tags" :key="tagKey">{{ tagItem }}</h6>
    </div>
  </div>
</template>

<script>
import { orientationClass } from '@/utils';

export default {
  name: 'CoreTile',

  props: {
    slug         : String,
    routeName    : {
      type: String,
      default: 'projectsEntry'
    },
    title        : String,
    tagline      : String,
    tags         : Array, String,
    orientation  : String,
    image        : Object
  },

  computed: {
    imageClass() {
      return orientationClass(this.orientation)
    }
  },

  methods: {
    routeMe(slug) {
      this.$router.push({
        name: this.routeName,
        params: { slug: slug }
      })
    }
  }
}
</script>
