import { GRID_CLASS, BREAKPOINT_MODIFIERS } from '@/constants';

export const gridClass = (
  col,
  colNs,
  colM,
  colL
  ) => {

  let className = ''
  typeof col   !== undefined && ( className = `${className} ${GRID_CLASS[col]   || ''}` )
  typeof colNs !== undefined && ( className = `${className} ${GRID_CLASS[colNs] || ''}${GRID_CLASS[colNs] ? BREAKPOINT_MODIFIERS['ns'] : ''}` )
  typeof colM  !== undefined && ( className = `${className} ${GRID_CLASS[colM]  || ''}${GRID_CLASS[colM] ? BREAKPOINT_MODIFIERS['m'] : ''}` )
  typeof colL  !== undefined && ( className = `${className} ${GRID_CLASS[colL]  || ''}${GRID_CLASS[colL] ? BREAKPOINT_MODIFIERS['l'] : ''}` )

  // Manage Defaults
  const defaultClass = [col, colNs, colM, colL].reduce((acc, value) => {
    return acc = typeof value === 'undefined' ? acc !== false && true : false
  }, typeof col === 'undefined' ? true : false)
  defaultClass && ( className = 'grid-6 grid-3-l' )



  return className
}