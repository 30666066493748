import { IMAGE_ORIENTATIONS, BREAKPOINT_MODIFIERS, H_ROW_POSITIONS, V_ROW_POSITIONS, FLUID_IMAGE_SIZES } from '@/constants';

export const orientationClass = (
  orientation,
  size,
  modifier = ''
  ) => {

  orientation = IMAGE_ORIENTATIONS[orientation] 
    ? IMAGE_ORIENTATIONS[orientation] 
    : IMAGE_ORIENTATIONS.landscape

  if (orientation === IMAGE_ORIENTATIONS.landscape ||
      orientation ===  IMAGE_ORIENTATIONS.portrait ||
      orientation ===  IMAGE_ORIENTATIONS.original
    ) {
    size = (size = Number(size)) && size ? size : 3
  } else {
    size = ''
  }
  
  modifier = BREAKPOINT_MODIFIERS[modifier] 
    ? BREAKPOINT_MODIFIERS[modifier] 
    : ''

  return orientation + size + modifier
}

export const hPositionClass = hPos => {
  return H_ROW_POSITIONS[hPos]
}

export const vPositionClass = vPos => {
  return V_ROW_POSITIONS[vPos]
}

export const imageClassDesktop = (
  orientation,
  size
  ) => {

  if ([IMAGE_ORIENTATIONS.portrait, IMAGE_ORIENTATIONS.landscape, IMAGE_ORIENTATIONS.original].includes(orientation)) {
    return orientationClass(orientation, size, 'l')
  } else if (orientation === IMAGE_ORIENTATIONS.fluid) {
    return FLUID_IMAGE_SIZES[size]
  }
  return false
}