<template>
  <div class="data-line">
    <!-- Text based field -->
    <template v-if="['text', 'author'].includes(handle)">
      <li v-for="(line, i) in content" :key="i">{{ line.label }}: {{ line.value }}</li>
    </template>

    <!-- Post Date -->
    <!-- Only ever going to be 1 post date -->
    <li v-if="['postDate'].includes(handle)">{{ content[0].label }}: {{ content[0].value | date }}</li>

    <!-- Press -->
    <!-- Only ever going to be 1 press entry -->
    <li v-if="['press'].includes(handle)">{{ content[0].label }}:
      <a v-for="(link, i) in content" :key="i"
        :href="link.value"
        target="_blank"
        class="data-link">{{ link.valueLabel }}
      </a>
    </li>

    <!-- Photography -->
    <!-- Only ever going to be 1 photography entry -->
    <li v-if="['photography'].includes(handle)">{{ content[0].label }}:
      <a v-for="(link, i) in content" :key="i"
        :href="link.value"
        target="_blank"
        class="data-link">{{ link.valueLabel }}
      </a>
    </li>
    
  </div>
</template>

<script>
export default {
  name: 'BlockDataLine',

  props: {
    handle: String,
    content: Array
  }
}
</script>