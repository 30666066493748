<template>
  <CoreGrid :col="3" class="flex-l nav-side items-center dn">
    <div
      class="nav-nodule"
      :style="leftPosition"
      :class="{ 'nav-nodule-home': homeOpacity }"
    ></div>
    <router-link
      class="h-100 flex items-center nav-button"
      v-for="(item, index) in $const.APP_GLOBAL_NAVIGATION"
      :key="index"
      :to="{ name: $const.NAVIGATION_ROUTES[item.handle] }"
      v-text="item.title"
      ref="navButtons"
      :d="routeIndex"
    />
  </CoreGrid>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'NavDesktop',

  data: () => ({
    leftPositions: [],
    route: 'home'
  }),

  computed: {
    routeIndex() {
      const transitionGroup = Object.values(
        this.$const.TRANSITION_GROUPS
      ).find(navGroup => navGroup.includes(this.route))
      const routeHandle = Object.keys(this.$const.TRANSITION_GROUPS).find(
        key => this.$const.TRANSITION_GROUPS[key] === transitionGroup
      )
      const itemValue = this.$const.APP_GLOBAL_NAVIGATION.find(
        el => el.handle === routeHandle
      )
      return this.$const.APP_GLOBAL_NAVIGATION.indexOf(itemValue)
    },
    leftPosition() {
      const i = this.routeIndex !== -1 ? this.routeIndex : 0
      return {
        left: `${this.leftPositions[i]}px`
      }
    },
    homeOpacity() {
      return this.routeIndex === -1 ? true : false
    }
  },

  watch: {
    $route: function(to) {
      this.route = to.name
    }
  },

  mounted() {
    window.addEventListener('resize', debounce(this.getLefts, 300), false)
    this.getLefts()
    this.route = this.$route.name
  },

  methods: {
    getLefts() {
      this.leftPositions = this.$refs.navButtons.map(
        el => el.$el.getBoundingClientRect().left
      )
    }
  }
}
</script>
