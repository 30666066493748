import debounce from 'lodash.debounce'
import { storage } from '@/utils'
import { APP_PREVIEW_MODE } from '@/constants'

export const preview = {
  /**
   * Current active state is true when the route matches the Preview path
   * @return {boolean}
   */
  activeState: false,

  /**
   * Resource key for storage identification
   * @return {string}
   */
  resourceKey: 'previewMode.scrollY',

  /**
   * Current location path is the preview path
   * @param  {string} path The router path to validate
   * @return {boolean}
   */
  isActive(path) {
    this.activeState = this.enabled && path === window.location.pathname
    return this.activeState
  },

  /**
   * Preserve the current scroll position
   * @param {boolean} bool 
   */
  preserveScroll(bool) {
    if (this.enabled) {
      if (!bool) this._setScrollPosition(0)
      window[bool ? 'addEventListener' : 'removeEventListener']('scroll', this._scrollBehavior)
    }
  },

  /**
   * Restore saved scroll position
   */
  restoreScroll() {
    this.enabled && this.activeState && window.scrollTo(0, this._getScrollPosition() || 0)
  },

  /**
   * Scroll callback
   * @access private
   */
  _scrollBehavior: debounce(() => {
    let $this = preview
    $this.enabled && $this.activeState && $this._setScrollPosition(window.scrollY)
  }, 300),

  /**
   * Persist the current scrollY position
   * @access private
   * @param {number} scrollY
   */
  _setScrollPosition(scrollY) {
    storage.api.setItem(this.resourceKey, scrollY)
  },

  /**
   * Returns a saved scroll position
   * @access private
   * @return {number}
   */
  _getScrollPosition() {
    return storage.api.getItem(this.resourceKey)
  },

  /**
   * Preview mode is determined by a truthy constant
   * @return {boolean}
   */
  get enabled() {
    return !!APP_PREVIEW_MODE
  },

  /**
   * Returns the Preview path
   * @access private
   * @return {string}
   */
  get path() {
    return this.enabled && APP_PREVIEW_MODE.path
  },

  /**
   * Returns the Preview token params
   * @access private
   * @return {object}
   */
  get params() {
    return (this.enabled && APP_PREVIEW_MODE.params) || {}
  }
}