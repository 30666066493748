<template>
  <div class="legal">
    <CoreContainer class="normal-content-spacing">
      <CoreRow>
        <CoreGrid>
          <h1 class="f5 sans" v-text="title"></h1>
        </CoreGrid>
        <CoreGrid v-html="copy" />
      </CoreRow>
    </CoreContainer>
  </div>
</template>

<script>
export default {
  name: "BlockLegal",

  props: {
    title: {
      type: String
    },
    copy: {
      type: String
    }
  }
}
</script>