<template>
  <div
    class="transition"
    :class="{ 'transition-not-active': !transitionActive }"
  >
    <div
      class="transition-intersection-bg"
      :class="[
        { 'transition-intersection-bg-active': intersectionBgActive },
        backgroundColor
      ]"
    ></div>
    <div
      class="transition-section-bg"
      :class="[
        { 'transition-section-bg-active': sectionBgActive },
        backgroundColor
      ]"
    ></div>
    <div class="alpha" :class="{ 'alpha-on': sectionBgActive }">
      <div
        v-if="!ie11"
        class="transition-animation"
        :class="{ 'transition-animation-loading': !loadingComplete }"
        ref="transitionElm"
      ></div>
      <div v-if="ie11" class="ie11-container">
        <img :src="require(`@/assets/images/monogram.png`)" />
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import router from '@/router'
import { mapState } from 'vuex'

const lottieConfigDefaults = {
  renderer: 'html'
}

export default {
  name: 'CoreTransition',

  data: () => ({
    sectionBgActive: true,
    transitionActive: false,
    intersectionBgActive: false,
    loadingComplete: false,
    transitionWaiting: null
  }),

  computed: {
    ...mapState({
      requestResolving: state => state.requestResolving,
      backgroundColor: state => state.backgroundColor,
      ie11: state => state.ie11
    })
  },

  watch: {
    requestResolving: function(val) {
      if (
        typeof Object.getOwnPropertyDescriptor(this, 'transitionWaiting') ===
        'object'
      ) {
        this.transitionWaiting = !val
        this.$set(this, 'transitionWaiting', null)
      }
    }
  },

  mounted() {
    const config = new Map([
      [
        'loading',
        {
          ...lottieConfigDefaults,
          container: this.$refs.transitionElm,
          path: '/animation/transition.json'
        }
      ],
      [
        'opening',
        {
          ...lottieConfigDefaults,
          container: this.$refs.transitionElm,
          path: '/animation/landing.json'
        }
      ]
    ])

    router.beforeResolve((to, from, next) =>
      this.handleNavigation(to, from, next, config)
    )
  },

  methods: {
    handleNavigation(to, from, next, config) {
      const transitionGroups = Object.values(this.$const.TRANSITION_GROUPS)
      const transitionWithinSections = transitionGroups.some(transitionGroup =>
        [to.name, from.name].every(elem => transitionGroup.indexOf(elem) > -1)
      )

      if (transitionWithinSections) this.doIntersectionTransition(next)
      else if (from.name === null)
        this.doSectionTransition('opening', from, next, config)
      else this.doSectionTransition('loading', from, next, config)
    },
    doIntersectionTransition(next) {
      this.setTransition(true)
      this.intersectionBackgroundSeqence(true)
        .then(() => this.setScrollPosition())
        .then(() => next())
        .then(() => this.waitForAPI(true))
        .then(() => this.resetLegal())
        .then(() => this.intersectionBackgroundSeqence(false))
        .then(() => this.setTransition(false))
    },
    doSectionTransition(type, from, next, config) {
      this.setTransition(true).then(() => from.name !== null && next()) // Loading internally
      this.disableScrollAndPointer(true)
      this.sectionBackgroundSeqence(true)
      this.sectionAnimationSeqence(config.get(type))
        .then(chain => this.setScrollPosition(chain))
        .then(chain => this.waitForAPI(chain))
        .then(chain => this.setTransition(false, chain))
        .then(chain => chain && chain.destroy())
        .then(() => this.resetLegal())
        .then(() => this.sectionBackgroundSeqence(false))
        .then(() => this.disableScrollAndPointer(false))
        .then(() => from.name === null && (this.loadingComplete = true))

      // if loading from an external link
      from.name === null && next()
    },
    setScrollPosition(chain) {
      window.scrollTo(0, 0)
      return chain
    },
    waitForAPI(chain) {
      // Wait untill the API calls have resolved before resolving the transition

      // make this accept a null value for lottie
      if (this.requestResolving === false) return chain

      return new Promise(resolve => {
        Object.defineProperty(this, 'transitionWaiting', {
          set: value => value && resolve(chain)
        })
      })
    },
    setTransition(bool, chain) {
      this.transitionActive = bool
      // setTimeout(() => this.transitionActive = bool, 200)
      return new Promise(
        resolve =>
          setTimeout(() => resolve(chain ? chain : null), bool ? 1000 : 333),
        false
      ) // delay to allow css to fade opacity to 0
    },
    intersectionBackgroundSeqence(bool) {
      bool && (this.intersectionBgActive = bool)
      // You have to delay this to give the browser time to load the images, nornally doesn't work anyway
      !bool && setTimeout(() => (this.intersectionBgActive = bool), 300)
      return new Promise(resolve => setTimeout(() => resolve(), 500), false)
    },
    sectionBackgroundSeqence(bool) {
      this.sectionBgActive = bool
    },
    sectionAnimationSeqence(config) {
      // minimum delay to make the logo briefly visible and not a flash
      if (!this.ie11) {
        lottie.play()
        // temporary fix. Lottie not returning on Complete callback
        // transition test below

        const transition = lottie.loadAnimation(config)
        const time =
          transition.fileName && transition.fileName === 'landing' ? 2600 : 1800
        // transition.complete = () => console.log('complete')
        // temp fix
        return new Promise(
          resolve => setTimeout(() => resolve(transition), time),
          false
        )
        // previous working code
        // return new Promise(
        //   resolve =>
        //     (transition.onCompleted = () => {
        //       // Loop the transition for load
        //       // transition.loop = true
        //       // lottie.play()
        //       resolve(transition)
        //     }),
        //   false
      } else {
        return new Promise(
          resolve => setTimeout(() => resolve(false), 1000),
          false
        ) // minimum delay to make the logo briefly visible and not a flash
      }
    },
    disableScrollAndPointer(bool) {
      // Do not add overflow hidden if scroll bars exist to avoid jumping
      document.body.clientWidth === window.innerWidth &&
        this.$store.dispatch('setStopScrolling', bool)
      this.$store.dispatch('setStopPointer', bool)
    },
    resetLegal() {
      this.$store.commit('setLegalVisible', false)
    }
  }
}
</script>
