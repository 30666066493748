<template>
  <div :class="className">
    <slot />
  </div>
</template>

<script>
import { gridClass } from '@/utils';

export default {
  name: 'CoreGrid',

  props: {
    col  : Number,
    colNs: Number,
    colM : Number,
    colL : Number
  },

  computed: {
    className() {
      return gridClass(this.col, this.colNs, this.colM, this.colL)
    }
  },
}
</script>
