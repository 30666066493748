<template>
  <div
    class="carousel"
    :class="{'follow-mouse': followMouse}"
    @click="progressSlide"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    @mousemove="mousemove"
  >
    <flickity
      ref="flickity"
      :options="flktyOpts"
    >
      <div
        v-for="(img, i) in efficiantImages"
        :key="i"
        class="carousel-cell overflow-hidden"
      >
        <CoreImage
          v-bind="img"
          className="carousel-image"
          :dprEnabled="dprEnabled"
        />
      </div>
    </flickity>
    <div
      class="slider-page"
      v-if="paginationVisible"
      :style="paginationPosition"
    >
      <p class="mb0">{{ slideStr }}</p>
    </div>
  </div>
</template>

<script>
import { default as Flickity } from '@c/Flickity.Wrap'
import { mapState } from 'vuex'

export default {
  name: 'CoreCarousel',

  components: {
    Flickity
  },

  props: {
    imgs: Array,
    initialIndex: {
      type: Number,
      default: 0
    },
    flickity: Object,
    followMouse: {
      type: Boolean,
      default: false
    },
    dprEnabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentSlide: this.initialIndex,
      flktyOpts: {
        arrowShape: { 
          x0: 10,
          x1: 70, y1: 50,
          x2: 70, y2: 10,
          x3: 70
        },
        pageDots: false,
        wrapAround: true,
        initialIndex: this.initialIndex,
        autoPlay: true,
        prevNextButtons: false,
        fade: true,
        accessibility: false,
        ...this.flickity
      },
      mouseIsHovering: false,
      paginationPosition: {
        top: 0,
        left: 0
      },
    }
  },

  computed: {
    ...mapState({
      requestResolving: state => state.requestResolving,
    }),
    totalSlides() {
      return this.imgs.length;
    },
    slideStr() {
      return `${this.currentSlide + 1}/${this.totalSlides}`
    },
    paginationVisible() {
      return !this.followMouse || this.mouseIsHovering
    },
    efficiantImages() {
      return [...this.imgs]
      // return this.imgs.map((img, i) => {
      //   if ([this.currentSlide, this.nextSlide].includes(i)) {
      //     return img
      //   } else {
      //     return {
      //       ...img,
      //       src: [{
      //         ...img.src[img.src.length - 1]
      //       }]
      //     }
      //   }
      // })
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.flickity.on('change', slideIndex => {
        this.currentSlide = typeof slideIndex === 'number' ? slideIndex : this.initialIndex
      })
    })

    // Delay begining of the auto play for transition to complete
    this.$refs.flickity.pausePlayer()
    setTimeout(() => {
      this.$refs.flickity.unpausePlayer()
    }, this.flktyOpts.autoPlay === true ? 1500 : this.flktyOpts.autoPlay);
  },

  methods: {
    progressSlide() {
      this.$refs.flickity.next()
    },
    mouseover() {
      this.mouseIsHovering = true
    },
    mouseleave() {
      this.mouseIsHovering = false
    },
    mousemove(e) {
      this.paginationPosition.left = `${e.clientX}px`
      this.paginationPosition.top  = `${e.clientY}px`
    },
  }
}
</script>