import Vue from 'vue'
import Vuex from 'vuex'
import { COLORS, AUXCOLORS } from "@/constants"

Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
    backgroundColor: COLORS.default,
    auxiliaryBackgroundColor: AUXCOLORS.default,
    mobileMenuVisible: false,
    isMobile: true,
    headerRemoved: false,
    stopScrolling: false,
    stopPointer: false,
    requestResolving: false,
    webShareAPI: !!navigator.share,
    legalVisible: false,
    footerHeight: 0,
    ie11: (!!window.MSInputMethodContext && !!document.documentMode),
    isEdge: window.navigator.userAgent.indexOf("Edge") > -1
  },

  mutations: {
    setBackgroundColor (state, payload) {
      state.backgroundColor = COLORS[payload]
    },
    setAuxiliaryBackgroundColor (state, payload) {
      state.auxiliaryBackgroundColor = AUXCOLORS[payload]
    },
    setMobileMenuVisible (state, payload) {
      state.mobileMenuVisible = payload
    },
    setIsMobile (state, payload) {
      state.isMobile = payload
    },
    setHeaderRemoved (state, payload) {
      state.headerRemoved = payload
    },
    setStopScrolling (state, payload) {
      state.stopScrolling = payload
    },
    setStopPointer (state, payload) {
      state.stopPointer = payload
    },
    setRequestResolving (state, payload) {
      state.requestResolving = payload
    },
    setLegalVisible (state, payload) {
      state.legalVisible = payload
    },
    setFooterHeight (state, payload) {
      state.footerHeight = payload
    }
  },
  
  actions: {
    setIsMobile ({commit, state, dispatch}, payload) {
      commit('setIsMobile', payload)
      !payload && state.mobileMenuVisible && dispatch('toggleMenu')
    },
    setStopScrolling ({commit, state}, payload) {
      commit('setStopScrolling', payload)
      document.body.classList[state.stopScrolling ? 'add' : 'remove']('stop-scrolling')
    },
    setStopPointer ({commit, state}, payload) {
      commit('setStopPointer', payload)
      document.body.classList[state.stopPointer ? 'add' : 'remove']('no-pointer-events')
    },
    toggleMenu ({commit, state, dispatch}) {
      commit('setMobileMenuVisible', !state.mobileMenuVisible)
      dispatch('setStopScrolling', state.mobileMenuVisible)
    },
    toggleLegal ({commit, state}) {
      commit('setLegalVisible', !state.legalVisible)
      // dispatch('setStopScrolling', state.legalVisible)
    },
    setFooterHeight ({commit, state}, payload) {
      state.footerHeight !== payload && commit('setFooterHeight', payload)
    }
  }
})
