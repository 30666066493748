// eslint-disable-next-line
import { APP_API_ENDPOINT, APP_WEBP_SUPPORT } from '@/constants'
import { preview } from '@/utils'
import store from '@/store'
import axios from 'axios'

// Configure API instance
const instance = axios.create()

// Intercept requests
instance.interceptors.request.use(
  (config) => {
    // Set the base URL for API requests
    config.baseURL = APP_API_ENDPOINT

    // WebP server-side content negotiation via Accept header
    if (APP_WEBP_SUPPORT) {
      const accept = config.headers.common.Accept.split(', ')
      accept.splice(accept.length - 1, 0, 'image/webp')
      config.headers.common.Accept = accept.join(', ')
    }

    // Add a query string to pick the correct version of the request out the cache
    config.params = {
      ...config.params,
      webp: APP_WEBP_SUPPORT
    }

    /**
     * Forward Craft Preview tokens to API
     * @since Craft 3.2
     */
    if (preview.enabled) {
      if (!config.params) config.params = preview.params
      else Object.keys(preview.params).map(key => config.params[key] = preview.params[key])
    }

    // Inform store of this request
    store.commit('setRequestResolving', true)

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

/**
 * Handles instance responses
 * @param  {object} response Axios response schema
 * @return {object}
 */
const handleResponse = (response) => {
  // Inform store that request is completed
  store.commit('setRequestResolving', false)

  // Returns element-api resource key by default
  return ('data' in response.data)
    ? response.data.data
    : response.data
}

export const $http = {
  /**
   * Get request wrapper
   * @param  {string} url      Request URL
   * @param  {object} config   Request config options
   * @return {object}
   */
  get(url, config) {
    return instance.get(url, config).then(handleResponse)
  }
}