import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { preview } from '@/utils'
import { routes } from '@/routes'

Vue.use(Router)

const router = new Router({
  // Craft Preview requires abstract mode enabled
  mode: preview.enabled ? 'abstract' : 'history',
  base: '/',
  routes
  // scrollBehavior() {
  //   // Delayed for the transition to complete before scroll changes
  //   return new Promise(resolve => setTimeout(() => resolve({ x: 0, y: 0 }), 500))
  // }
})

export default router

// Replace Craft's preview path with actual
preview.enabled && router.replace({ path: preview.path })

// Navigation guards
router.beforeResolve((to, from, next) => {
  // bg color
  if (to.meta.background) store.commit('setBackgroundColor', to.meta.background)
  else if (from.meta.background) store.commit('setBackgroundColor', 'default')

  // auxiliary bg color
  if (to.meta.auxiliaryBackground)
    store.commit('setAuxiliaryBackgroundColor', to.meta.auxiliaryBackground)
  else if (from.meta.auxiliaryBackground)
    store.commit('setAuxiliaryBackgroundColor', 'default')

  next()
})
