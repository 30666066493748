<template>
  <div class="video">
    <video
      v-if="!isSafari && !ie11 && !isEdge"
      v-bind="videoAttributes"
      autoplay
    >
      <source
        v-for="(_src, i) in filteredSrc"
        :key="i"
        :media="`(max-width: ${_src.width}px)`"
        :src="_src.src"
        :type="_src.type"
      >
    </video>
    <video
      v-if="isSafari || ie11 || isEdge"
      :src="safariSrc.src"
      :type="safariSrc.type"
      v-bind="videoAttributes"
      autoplay
    ></video>
     
    <div class="_mute-unmute" @click="toggleMuteUnmute" v-if="allowAudio"> <!-- mute / unmute -->
      <CoreMediaMuteUnmute :muted="muted" />
    </div>

    <div
      v-if="controls"
      :class="{ 'autoplay-controls' : paused }"
      class="controls"
    >
      <div class="_play-pause" @click="togglePlayPause"> <!-- play / pause -->
        <CoreMediaPlayPause :paused="paused" />
      </div>
      <!-- playbar -->
      <div
        class="playbar"
        ref="playbar"
        @click="timeUpdate"
      >
        <div class="playbar-outer">
          <div
            class="playbar-inner"
            ref="playbarInner"
          ></div>
        </div>
      </div>
      <div class="_mute-unmute" @click="toggleMuteUnmute"> <!-- mute / unmute -->
        <CoreMediaMuteUnmute :muted="muted" />
      </div>
    </div>
  </div>
</template>

<script>
import { CoreMediaPlayPause, CoreMediaMuteUnmute } from './video'
import { mapState } from 'vuex'

export default {
  name: 'CoreVideo',

  components: {
    CoreMediaPlayPause,
    CoreMediaMuteUnmute
  },

  props: {
    image: Object,
    src: Array,
    title: String,
    allowAudio: {
      type: Boolean,
      default: false
    },
    className: {
      type: [String, Object, Array],
      default: "image-contained"
    },
    controls: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    paused   : false,
    muted    : true,
    duration : 1
  }),

  computed: {
    ...mapState({
      ie11: state => state.ie11,
      isEdge: state => state.isEdge
    }),
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },
    filteredSrc() {
      // Remove source video
      return this.src.filter(video => video.type !== "source")
    },
    safariSrc() {
      return [...this.filteredSrc].shift()
    },
    videoAttributes() {
      return {
        title: this.title,
        class: [this.className, "video-core"],
        ref: "videoCore",
        playsinline: "playsinline",
        preload: "auto",
        poster: this.image.src[0].src,
        ...(this.autoplay && {
          // When applying autoplay via this mobile safari does not respect it
          // autoplay: "autoplay",
          muted: "muted",
          loop: "loop"
        })
      }
    }
  },

  mounted() {
    if (this.controls) {
      this.autoplayControls = this.autoplay
      !this.autoplay && (this.muted  = false)
      !this.autoplay && (this.paused = true)

      const video        = this.$refs.videoCore
      const playbarInner = this.$refs.playbarInner

      video.onvolumechange   = () => this.muted    = video.muted
      video.onpause          = () => this.paused   = video.paused
      video.onplay           = () => this.paused   = video.paused
      video.ondurationchange = () => this.duration = video.duration
      video.ontimeupdate     = () => playbarInner.style.width = `${video.currentTime / this.duration * 100}%`

      this.$watch(
        ()  => { return this.muted },
        val => val !== video.muted && (video.muted = val)
      )
      this.$watch(
        ()  => { return this.paused },
        val => val !== video.paused && val ? video.pause() : video.play()
      )
    } else {
      const video        = this.$refs.videoCore
      this.$watch(
        ()  => { return this.muted },
        val => val !== video.muted && (video.muted = val)
      )
    }
  },

  methods: {
    togglePlayPause() { this.paused = !this.paused },
    toggleMuteUnmute() { this.muted = !this.muted },
    timeUpdate(e) { 
      this.$refs.videoCore.currentTime = this.duration * e.offsetX / this.$refs.playbar.getBoundingClientRect().width
    }
  }
}
</script>