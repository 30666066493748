<template>
  <div class="play-pause">
    <div :style="play" class="video-control-icon-container">
      <img src="~@/assets/icons/pause.svg" alt="pause" class="video-control-icon">
    </div>
    <div :style="pause" class="video-control-icon-container">
      <img src="~@/assets/icons/play.svg" alt="play" class="video-control-icon">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoreMediaPlayPause',

  props: {
    paused: Boolean
  },

  computed: {
    play() {
      return {
        display:  this.paused ? 'none' : 'flex'
      }
    },
    pause() {
      return {
        display: !this.paused ? 'none' : 'flex'
      }
    }
  }
}
</script>