<template>
  <div class="signup mt4">
    <div class="sans-extra-bold" v-text="title"></div>
    <div id="mc_embed_signup">
      <form
        action="https://jackdawstudio.us16.list-manage.com/subscribe/post?u=a17675772046fe5dde8f7c804&amp;id=5f235e2967"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup_scroll" class="mb4">
          <div class="email-subscribe-container" :style="emailBorderStyle">
            <div class="mc-field-group">
              <input
                placeholder="Enter your email"
                type="email"
                value
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                ref="focusTarget"
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button submit-sibling"
                :style="submitStyle"
              />
            </div>
          </div>
          <div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group focus-sibling" :style="focusStyle">
            <div class="content__gdpr">
              <p>{{ optin }}</p>
              <fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
                <label class="checkbox subfield dn" for="gdpr_1491">
                  <input
                    type="checkbox"
                    id="gdpr_1491"
                    name="gdpr[1491]"
                    value="Y"
                    class="av-checkbox"
                  />
                  <span class="check-label">Email</span>
                </label>
              </fieldset>
              <p
                class="conditions"
              >{{ terms }}</p>
            </div>
            <div class="content__gdprLegal">
              <p class="conditions dn">
                We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.
                <a
                  href="https://mailchimp.com/legal/"
                  target="_blank"
                >Learn more about Mailchimp's privacy practices here.</a>
              </p>
            </div>
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>
          <!--bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input type="text" name="b_a17675772046fe5dde8f7c804_5f235e2967" tabindex="-1" value />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockSignup",

  props: {
    title: {
      type: String
    },
    optin: {
      type: String
    },
    terms: {
      type: String
    }
  },

  data: () => ({
    additionalInfoState: false,
    submitState: false
  }),

  computed: {
    focusStyle() {
      return {
        opacity: this.additionalInfoState ? "1" : "0",
        transform: this.additionalInfoState ? "translateY(0)" : "translateY(-10px)"
      }
    },
    submitStyle() {
      return {
        opacity: this.submitState ? "1" : "0",
        transform: this.submitState ? "translateX(0)" : "translateX(-10px)"
      }
    },
    emailBorderStyle() {
      return {
        borderBottom: this.additionalInfoState ? "1px solid black" : "1px solid #A4A4A4"
      }
    }
  },

  mounted() {
    this.$refs.focusTarget.addEventListener('focus', this.handleFocus)
    this.$refs.focusTarget.addEventListener('blur', this.handleFocus)
    this.$refs.focusTarget.addEventListener('keyup', this.handleValue)
  },

  beforeDestroy() {
    this.$refs.focusTarget.removeEventListener('focus', this.handleFocus)
    this.$refs.focusTarget.removeEventListener('blur', this.handleFocus)
    this.$refs.focusTarget.removeEventListener('keyup', this.handleValue)
  },

  methods: {
    handleFocus(e) {
      if (e.type === "focus" || e.type === "blur" && e.target.value !== "") this.additionalInfoState = true
      else this.additionalInfoState = false
    },
    handleValue(e) {
      this.submitState =  e.target.value !== "" ? true : false
    }
  }
}
</script>