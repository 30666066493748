<template>
  <div class="animation" ref="animation"></div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'CoreAnimation',

  props: {
    src: String,
    loop: Boolean
  },

  data: () => ({
    transition: null
  }),

  mounted() {
    this.transition = lottie.loadAnimation({
      renderer: 'html',
      container: this.$refs.animation,
      path: this.src,
      loop: this.loop,
      autoplay: true
    })

    lottie.play()
  },

  beforeDestroy() {
    this.transition.destroy()
  }
}
</script>
