import Vue from 'vue'
import moment from 'moment'

const capitalize = value => {
  if (!value) return ''
  return String(value).charAt(0).toUpperCase() + String(value).slice(1)
}

const date = value => {
  if (!value) return ''
  return moment(String(value)).format('dddd DD MMMM YYYY')
}

const filters = {
  capitalize  : capitalize,
  date        : date
}

Object.keys(filters).forEach(filter => Vue.filter(filter, filters[filter]))