<template>
  <div id="app" :class="[backgroundColor, auxiliaryBackgroundColor]">
    <transition name="fade">
      <BlockNav key="nav" v-if="isNotHome" />
    </transition>
    <RouterView />
    <BlockCookies />
    <CoreTransition v-if="!$const.APP_PREVIEW_MODE" />
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'
import { BlockNav, BlockCookies } from '@c/block'

export default {
  name: 'App',

  // Setting the default
  metadata() {
    return {
      titleTemplate: this.$const.APP_METADATA.title
    }
  },

  components: {
    BlockNav,
    BlockCookies
  },

  data: () => ({
    isMobile: true,
    isNotHome: false
  }),

  computed: {
    ...mapState({
      backgroundColor: state => state.backgroundColor,
      auxiliaryBackgroundColor: state => state.auxiliaryBackgroundColor
    })
  },

  watch: {
    // Remove nav bar on home page
    $route(to) {
      this.isNotHome = to.name === 'home' ? false : true
    }
  },

  created() {
    // Debounce isMobile
    window.addEventListener('resize', debounce(this.resize, 300), false)
    this.resize()
  },

  methods: {
    resize() {
      this.$store.dispatch(
        'setIsMobile',
        !window.matchMedia('(min-width: 60em)').matches
      )
    }
  }
}
</script>

<style lang="scss" src="./styles/app"></style>
