<template>
  <div
    class="share"
    :class="{'share-open': shareOpen}"
    @mouseover="standardShare"
    @mouseout="handleMouseOut"
  >
    <div class="share-button" @click="webShare">Share +</div>
    <ul class="share-list">
      <template v-for="channel in channels">
        <!-- Twitter -->
        <a
          v-if="channel.channel === 'twitter'"
          :key="channel.channel"
          class="share-item"
          target="_blank"
          :href="`https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hashtags}&via=${channel.username}`"
        >Twitter</a>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BlockShare',

  data: () => ({
    webShareEnabled: true,
    shareOpen: false
  }),

  computed: {
    ...mapState({
      webShareAPI: state => state.webShareAPI
    }),
    channels() {
      return this.$const.APP_GLOBAL_SOCIAL_MEDIA.channels.filter(channel => channel.enableAsShare)
    },
    url() {
      return window.location.href
    },
    text() {
      return this.$const.APP_GLOBAL_SOCIAL_MEDIA.shareText
    },
    hashtags() {
      return this.$const.APP_GLOBAL_SOCIAL_MEDIA.hashtags.map(hashtag => hashtag.hashtags).join(',')
    }
  },

  methods: {
    webShare() {
      if (!this.webShareAPI) return
      navigator.share({
        title: document.title,
        text: this.text,
        url: this.url,
      }).catch(() => this.standardShare())
    },
    standardShare() {
      if (this.webShareAPI) return
      this.shareOpen = true
    },
    handleMouseOut() {
      if (this.webShareAPI) return
      this.shareOpen = false
    }
  }
}
</script>


