let loaded = false

const initiatePinit = () => {
  loaded = true
  const pinitMain = document.createElement("script");
  // pinitMain.setAttribute(
  //   'src',
  //   `https://assets.pinterest.com/js/pinit_main.js?0.06061869228089156`
  // )
  pinitMain.setAttribute(
    "src",
    "//assets.pinterest.com/js/pinit.js"
  );
  pinitMain.async = true;
  pinitMain.defer = true;
  // This does not work because pinit.js imports pinit_main.js
  // pinitMain.onload = () => ready = true 
  document.body.appendChild(pinitMain)
}

// A better implantation of this would be to use an object define property to await the ready state rather than a set interval
export const pinit = (fn = "pinOne") => {
  !loaded && initiatePinit()
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (window.PinUtils && window.PinUtils[fn]) {
        clearInterval(interval)
        resolve(window.PinUtils)
      }
    })
  }, 5)
}