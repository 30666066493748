import { APP_GLOBAL_NAVIGATION, NAVIGATION_ROUTES } from './constants'

const pathName = route => {
  const key = Object.keys(NAVIGATION_ROUTES).find(
    key => NAVIGATION_ROUTES[key] === route
  )
  const routeTitleObject = APP_GLOBAL_NAVIGATION.find(
    object => object.handle === key
  )
  return routeTitleObject.title.toLowerCase()
}

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    // meta: {
    // auxiliaryBackground: "beige"
    // }
  },
  {
    path: `/${pathName('projects')}`,
    name: 'projects',
    component: () =>
      import(/* webpackChunkName: "projects" */ './views/Projects.vue')
  },
  {
    path: `/${pathName('projects')}/:slug`,
    name: 'projectsEntry',
    component: () =>
      import(/* webpackChunkName: "projects" */ './views/ProjectEntry.vue'),
    meta: {
      background: 'white',
      auxiliaryBackground: 'black'
    }
  },
  {
    path: `/${pathName('studio')}`,
    name: 'studio',
    component: () =>
      import(/* webpackChunkName: "studio" */ './views/Studio.vue')
    // meta: {
    //   background: "black",
    //   auxiliaryBackground: "black"
    // }
  },
  {
    path: `/${pathName('latest')}`,
    name: 'latest',
    component: () =>
      import(/* webpackChunkName: "latest" */ './views/Latest.vue'),
    meta: {
      background: 'beige',
      auxiliaryBackground: 'beige'
    }
  },
  {
    path: `/${pathName('latest')}/:slug`,
    name: 'latestEntry',
    component: () =>
      import(/* webpackChunkName: "latest" */ './views/LatestEntry.vue'),
    meta: {
      background: 'lightBeige',
      auxiliaryBackground: 'beige'
    }
  },
  {
    path: `/${pathName('contact')}`,
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "studio" */ './views/Contact.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "studio" */ './views/404.vue')
  }
]
