import { TYPE_CLASSS } from '@/constants'

export const fontLoader = () => {
  const ul = document.createElement("ul")
  
  Object.values(TYPE_CLASSS).forEach(className => {
    const li = document.createElement("li")
    li.classList.add(className)
    ul.appendChild(li)
  });
  
  document.body.appendChild(ul)
  setTimeout(() => {
    document.body.removeChild(ul)
  })
}