export const arrayPairs = (
  data
  ) => {

  const parsed = data.reduce((a, now) => {
    if (
      a.length === 0 ||
      now.fullWidth === true ||
      now.type === 'letterbox' ||
      (a.length !== 0 && (a[a.length - 1][a[a.length - 1].length - 1].fullWidth === true || a[a.length - 1].length >= 2)) ||
      (a.length !== 0 && (a[a.length - 1][a[a.length - 1].length - 1].type === 'letterbox' || a[a.length - 1].length >= 2))
    ) {
      a.push([now])
      return a
    } else {
      a[a.length - 1].push(now)
      return a
    }
  }, new Array())

  return parsed
}

export const removeDuplicates = (
  a,
  key
) => {
  return a.filter((elm, i, arr) => {
    return arr.findIndex(z => z[key] === elm[key]) === i;
  })
}

export const removeMatchingObject = ( // remove object from array if it matches a key value pair, otherwise remove last item
  a,
  key,
  value
) => {
  a.splice(a.findIndex(z => z[key] === value), 1)
  return a
}