<template>
  <CoreContainer :contained="true" class="related">
    <h3
      class="mt4 invert related-title"
      v-html="relatedHeading"></h3>
    <CoreRow class="mt4">
      <CoreGrid
        :colL="2"
        v-for="(item, key) in items"
        :key="key"
        class="pointer"
      >
        <CoreTile :routeName="$const.RELATED_BLOCK_ROUTE_NAMES[$route.name]" v-bind="item" />
      </CoreGrid>
    </CoreRow>
  </CoreContainer>
</template>

<script>
export default {
  name: 'BlockRelated',

  props: {
    data: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: null
    }
  },

  data: () => ({
    items: []
  }),

  computed: {
    relatedHeading() {
      return this.$const.APP_GLOBAL_RELATED_HEADINGS[this.$route.name]
    }
  },

  // a child component of a route component
  created() {
    this.$api.getChannel({
      channel  : this.$const.RELATED_BLOCK_CHANNEL[this.$route.name],
      limit    : this.data ? 3 - this.data.length : 3,
      exclude  : [this.id, ...this.data.map(x => x.id)]
    }).then(response => this.items = [...this.data, ...response])
  }

  // methods: {
  //   routeMe(slug) {
  //     this.$router.push({
  //       name: this.$const.RELATED_BLOCK_ROUTE_NAMES[this.$route.name],
  //       params: { slug: slug }
  //     })
  //   }
  // }
}
</script>