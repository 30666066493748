<template>
  <footer class="footer contained" ref="footer">
    <hr>
    <CoreRow class="items-center-l mv3 mv4-l items-end-l flex-column-reverse flex-row-l"> <!-- using mv rather than content spacing because the logo is sqewing the margins -->
      <CoreGrid class="mt3 mt0-l">
        <span
          class="invert sans"
        >© Jackdaw 2019</span>
      </CoreGrid>
      <CoreGrid class="flex items-center-l justify-between justify-start-l">
        <p
          class="invert pointer ma0 flex justify-end justify-start-l"
          @click="top"
        >Top ↑</p>
        <div
          v-if="$route.name === 'contact'"
          @click="$store.dispatch('toggleLegal')"
          class="pointer invert sans ml5-l mt2 mt0-l flex justify-end justify-start-l"
        >
          {{ legalVisible ? 'Back' : 'Legal' }}
        </div>
        <router-link
          v-else
          class="invert sans ml5-l flex justify-end justify-start-l"
          :to="{ name: 'contact' }"
        >Get in touch</router-link>
        <GeometricLogo @click.native="routeMe" class="h2 footer-logo" />
      </CoreGrid>
    </CoreRow>
  </footer>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'
import GeometricLogo from '@c/GeometricLogo'

export default {
  name: 'BlockFooter',

  components: {
    GeometricLogo
  },

  computed: {
    ...mapState({
      legalVisible: state => state.legalVisible
    })
  },

  mounted() {
    smoothscroll.polyfill();

    this.debouncedResize = debounce(this.resize, 300)
    window.addEventListener('resize', this.debouncedResize, false)
    this.resize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedResize, false)
  },

  methods: {
    top() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    routeMe() {
      this.$router.push({
        name: 'home'
      })
    },
    resize() {
      // console.log(this.$refs.footer)
      this.$store.dispatch('setFooterHeight', this.$refs.footer.getBoundingClientRect().height)
    }
  }
}
</script>