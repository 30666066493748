<template>
  <div>
    <CoreContainer
      v-if="visible && !headerRemoved"
      :contained="true"
      class="faux-nav"
    >
      <CoreRow>
        <CoreGrid :col="6" class="dn-l faux-nav-button">
          <p class="ma0" @click="handleClick">Close</p>
        </CoreGrid>
      </CoreRow>
    </CoreContainer>
    <CoreContainer :contained="true" class="nav-mobile-menu" v-if="visible">
      <div>
        <router-link
          class="db sans f3 mt3"
          v-for="(item, index) in $const.APP_GLOBAL_NAVIGATION"
          :key="index"
          :to="{ name: routes[item.handle] }"
          v-text="item.title"
          @click.native="handleClick"
        />
      </div>
    </CoreContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavMobileMenu',

  computed: {
    ...mapState({
      visible: state => state.mobileMenuVisible,
      headerRemoved: state => state.headerRemoved
    }),
    routes() {
      return this.$const.NAVIGATION_ROUTES
    }
  },

  methods: {
    handleClick() {
      // hide mobile nav
      this.$store.dispatch('toggleMenu')
    }
  }
}
</script>
