<template>
  <div :class="{ 'nav-faux-height': docked }">
    <div class="nav" :class="{ 'nav-fixed': docked }">
      <div class="nav-bar bb sans f5">
        <nav class="contained">
          <CoreRow>
            <CoreGrid :col="3" class="flex nav-side items-center">
              <router-link
                @click.native="toggleMenu"
                :class="[
                  'sans-extra-bold f4',
                  { vh: !(headerRemoved || docked) && $route.name === 'home' }
                ]"
                to="/"
              >Jackdaw</router-link>
            </CoreGrid>
            <NavDesktop v-if="!isMobile" />
            <NavMobile  v-else />
          </CoreRow>
        </nav>
      </div>
      <NavMobileMenu />
    </div>
  </div>
</template>

<script>
import { NavMobile, NavDesktop, NavMobileMenu } from '@c/block/nav'
import { mapState } from 'vuex'
import "intersection-observer"

export default {
  name: 'BlockNav',

  components: {
    NavMobile,
    NavDesktop,
    NavMobileMenu
  },

  props: {
    docked: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    logoVisible: false
  }),

  computed: {
    ...mapState({
      headerRemoved: state => state.headerRemoved,
      isMobile: state => state.isMobile,
      mobileMenuVisible: state => state.mobileMenuVisible
    })
  },

  methods: {
    toggleMenu() {
      this.mobileMenuVisible && this.$store.dispatch('toggleMenu')
    }
  }
}
</script>